*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: var(--fallback-b1, oklch(var(--b1) / 1));
  color: var(--fallback-bc, oklch(var(--bc) / 1));
}

@supports not (color: oklch(0 0 0)) {
  :root {
    color-scheme: light;
    --fallback-p: #491eff;
    --fallback-pc: #d4dbff;
    --fallback-s: #ff41c7;
    --fallback-sc: #fff9fc;
    --fallback-a: #00cfbd;
    --fallback-ac: #00100d;
    --fallback-n: #2b3440;
    --fallback-nc: #d7dde4;
    --fallback-b1: #fff;
    --fallback-b2: #e5e6e6;
    --fallback-b3: #e5e6e6;
    --fallback-bc: #1f2937;
    --fallback-in: #00b3f0;
    --fallback-inc: #000;
    --fallback-su: #00ca92;
    --fallback-suc: #000;
    --fallback-wa: #ffc22d;
    --fallback-wac: #000;
    --fallback-er: #ff6f70;
    --fallback-erc: #000;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      color-scheme: dark;
      --fallback-p: #7582ff;
      --fallback-pc: #050617;
      --fallback-s: #ff71cf;
      --fallback-sc: #190211;
      --fallback-a: #00c7b5;
      --fallback-ac: #000e0c;
      --fallback-n: #2a323c;
      --fallback-nc: #a6adbb;
      --fallback-b1: #1d232a;
      --fallback-b2: #191e24;
      --fallback-b3: #15191e;
      --fallback-bc: #a6adbb;
      --fallback-in: #00b3f0;
      --fallback-inc: #000;
      --fallback-su: #00ca92;
      --fallback-suc: #000;
      --fallback-wa: #ffc22d;
      --fallback-wac: #000;
      --fallback-er: #ff6f70;
      --fallback-erc: #000;
    }
  }
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  color-scheme: light;
  --in: .7206 .191 231.6;
  --su: 64.8% .15 160;
  --wa: .8471 .199 83.87;
  --er: .7176 .221 22.18;
  --pc: .89824 .06192 275.75;
  --ac: .15352 .0368 183.61;
  --inc: 0 0 0;
  --suc: 0 0 0;
  --wac: 0 0 0;
  --erc: 0 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: .4912 .3096 275.75;
  --s: .6971 .329 342.55;
  --sc: .9871 .0106 342.55;
  --a: .7676 .184 183.61;
  --n: .321785 .02476 255.702;
  --nc: .894994 .011585 252.096;
  --b1: 1 0 0;
  --b2: .961151 0 0;
  --b3: .924169 .00108 197.138;
  --bc: .278078 .029596 256.848;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --in: .7206 .191 231.6;
    --su: 64.8% .15 160;
    --wa: .8471 .199 83.87;
    --er: .7176 .221 22.18;
    --pc: .13138 .0392 275.75;
    --sc: .1496 .052 342.55;
    --ac: .14902 .0334 183.61;
    --inc: 0 0 0;
    --suc: 0 0 0;
    --wac: 0 0 0;
    --erc: 0 0 0;
    --rounded-box: 1rem;
    --rounded-btn: .5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: .25s;
    --animation-input: .2s;
    --btn-focus-scale: .95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: .5rem;
    --p: .6569 .196 275.75;
    --s: .748 .26 342.55;
    --a: .7451 .167 183.61;
    --n: .313815 .021108 254.139;
    --nc: .746477 .0216 264.436;
    --b1: .253267 .015896 252.418;
    --b2: .232607 .013807 253.101;
    --b3: .211484 .01165 254.088;
    --bc: .746477 .0216 264.436;
  }
}

[data-theme="light"] {
  color-scheme: light;
  --in: .7206 .191 231.6;
  --su: 64.8% .15 160;
  --wa: .8471 .199 83.87;
  --er: .7176 .221 22.18;
  --pc: .89824 .06192 275.75;
  --ac: .15352 .0368 183.61;
  --inc: 0 0 0;
  --suc: 0 0 0;
  --wac: 0 0 0;
  --erc: 0 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: .4912 .3096 275.75;
  --s: .6971 .329 342.55;
  --sc: .9871 .0106 342.55;
  --a: .7676 .184 183.61;
  --n: .321785 .02476 255.702;
  --nc: .894994 .011585 252.096;
  --b1: 1 0 0;
  --b2: .961151 0 0;
  --b3: .924169 .00108 197.138;
  --bc: .278078 .029596 256.848;
}

[data-theme="dark"] {
  color-scheme: dark;
  --in: .7206 .191 231.6;
  --su: 64.8% .15 160;
  --wa: .8471 .199 83.87;
  --er: .7176 .221 22.18;
  --pc: .13138 .0392 275.75;
  --sc: .1496 .052 342.55;
  --ac: .14902 .0334 183.61;
  --inc: 0 0 0;
  --suc: 0 0 0;
  --wac: 0 0 0;
  --erc: 0 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: .6569 .196 275.75;
  --s: .748 .26 342.55;
  --a: .7451 .167 183.61;
  --n: .313815 .021108 254.139;
  --nc: .746477 .0216 264.436;
  --b1: .253267 .015896 252.418;
  --b2: .232607 .013807 253.101;
  --b3: .211484 .01165 254.088;
  --bc: .746477 .0216 264.436;
}

[data-theme="winter"] {
  color-scheme: light;
  --pc: .91372 .051 257.57;
  --sc: .885103 .03222 282.339;
  --ac: .11988 .038303 335.171;
  --nc: .839233 .012704 257.652;
  --inc: .176255 .017178 214.515;
  --suc: .160988 .015404 197.824;
  --wac: .178345 .009167 71.4703;
  --erc: .146185 .022037 20.0763;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: .5686 .255 257.57;
  --s: .425516 .161098 282.339;
  --a: .599398 .191515 335.171;
  --n: .196166 .063518 257.652;
  --b1: 1 0 0;
  --b2: .974663 .011947 259.823;
  --b3: .932686 .016223 262.751;
  --bc: .418869 .053885 255.825;
  --in: .881275 .085888 214.515;
  --su: .804941 .077019 197.824;
  --wa: .891725 .045833 71.4703;
  --er: .730926 .110185 20.0763;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.input {
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-width: 1px;
  border-color: #0000;
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.range {
  cursor: pointer;
  appearance: none;
  --range-shdw: var(--fallback-bc, oklch(var(--bc) / 1));
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  overflow: hidden;
}

.range:focus {
  outline: none;
}

.steps .step {
  text-align: center;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  place-items: center;
  min-width: 4rem;
  display: grid;
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.input input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input:focus, .input:focus-within {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
}

.input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.input::-webkit-date-and-time-value {
  text-align: inherit;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  direction: ltr;
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .75rem;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .5rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }
}

.range:focus-visible::-webkit-slider-thumb {
  --focus-shadow: 0 0 0 6px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 2rem var(--range-shdw) inset;
}

.range:focus-visible::-moz-range-thumb {
  --focus-shadow: 0 0 0 6px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 2rem var(--range-shdw) inset;
}

.range::-webkit-slider-runnable-track {
  border-radius: var(--rounded-box, 1rem);
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  width: 100%;
  height: .5rem;
}

.range::-moz-range-track {
  border-radius: var(--rounded-box, 1rem);
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  width: 100%;
  height: .5rem;
}

.range::-webkit-slider-thumb {
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  appearance: none;
  color: var(--range-shdw);
  --filler-size: 100rem;
  --filler-offset: .6rem;
  box-shadow: 0 0 0 3px var(--range-shdw) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.range::-moz-range-thumb {
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  color: var(--range-shdw);
  --filler-size: 100rem;
  --filler-offset: .5rem;
  box-shadow: 0 0 0 3px var(--range-shdw) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
}

.range-accent {
  --range-shdw: var(--fallback-a, oklch(var(--a) / 1));
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes skeleton {
  from {
    background-position: 150%;
  }

  to {
    background-position: -50%;
  }
}

.steps .step:before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  content: "";
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: .5rem;
  margin-inline-start: -100%;
  top: 0;
}

.steps .step:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  border-radius: 9999px;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  place-items: center;
  width: 2rem;
  height: 2rem;
  display: grid;
  position: relative;
}

.steps .step:first-child:before {
  content: none;
}

.steps .step[data-content]:after {
  content: attr(data-content);
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.steps-horizontal .step {
  text-align: center;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: center;
  display: grid;
}

.steps-vertical .step {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.steps-horizontal .step {
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  min-width: 4rem;
}

.steps-horizontal .step:before {
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  width: 100%;
  height: .5rem;
  margin-inline-start: -100%;
}

:is([dir="rtl"] .steps-horizontal .step):before {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.steps-vertical .step {
  grid-template-rows: auto;
  grid-template-columns: 40px 1fr;
  justify-items: start;
  gap: .5rem;
  min-height: 4rem;
}

.steps-vertical .step:before {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: .5rem;
  height: 100%;
  margin-inline-start: 50%;
}

:is([dir="rtl"] .steps-vertical .step):before {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.float-right {
  float: right;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.flex {
  display: flex;
}

.w-40 {
  width: 10rem;
}

.w-full {
  width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.justify-between {
  justify-content: space-between;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.text-center {
  text-align: center;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-accent {
  --tw-text-opacity: 1;
  color: var(--fallback-a, oklch(var(--a) / var(--tw-text-opacity)));
}

.text-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
}

.text-success {
  --tw-text-opacity: 1;
  color: var(--fallback-su, oklch(var(--su) / var(--tw-text-opacity)));
}

/*# sourceMappingURL=index.c9140956.css.map */
